import React from 'react'
import "./Martha.css"
import Footer from '../Footer'
import ReactAudioPlayer from 'react-audio-player'
import { Link } from 'react-router-dom'

function Morgan() {
  return (
    <div>
      <>
        <div className='pic'>
          <img src="/images/mogan.jpg" alt="Artist" className='martha' />
        </div>
        <div className='container'>
          <h1>Modest Morgan</h1>
          <ul className='list'>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="/audio/simbawayudamogan.mpeg" />
              <a href="https://skizatune.com/6390205/" className='songName'>Simba wa Yuda</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390205/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390206/" className='songName'>Yesu Ni Upendo</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390206/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390201" className='songName'>Hakuna Kama Wewe</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390201">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390203/" className='songName'>Yuko Njiani</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390203/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390202" className='songName'>Masiah Masiah Yahwe</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390202">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390204" className='songName'>Naburudika</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390204">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
          </ul>
        </div>
        <Footer />
      </>
    </div>
  )
}

export default Morgan
