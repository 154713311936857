import React from 'react'
import "./Martha.css"
import Footer from '../Footer'
import ReactAudioPlayer from 'react-audio-player'
import { Link } from 'react-router-dom'

function Enock() {
  return (
    <div>
      <>
        <div className='pic'>
          <img src="/images/jonas.jpg" alt="Artist" className='martha' />
        </div>
        <div className='container'>
          <h1>Enock Jonas</h1>
          <ul className='list'>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391128/" className='songName'>Amani ya moyo wangu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391128/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="/audio/sikuyanguenock.mpeg" />
              <a href="https://skizatune.com/6391294/" className='songName'>Ipo siku yangu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391294/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391297" className='songName'>Moyo wangu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391297">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391129/" className='songName'>Bado naendelea chorus</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391129/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391133" className='songName'>Kwa uzuri wako</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391133">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391138" className='songName'>Yahweh</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391138">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls autoPlay src="" />
              <a href="https://skizatune.com/6391290" className='songName'>Happy Birthday Chorus</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391290">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls autoPlay src="" />
              <a href="https://skizatune.com/6391295" className='songName'>Kuwafunga mdomo chorus</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391295">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
          </ul>
        </div>
      </>
      <Footer />
    </div>
  )
}

export default Enock
