import React, { useState } from 'react';
import './SignUp.css';
import Footer from '../Footer';
import { Link, useNavigate } from 'react-router-dom';

function SignUp() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');

  const handleInputChange = (e, type) => {
    if (type === 'mobile') setMobile(e.target.value);
    if (type === 'password') setPassword(e.target.value);
    if (type === 'confirmPassword') setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mobile && password && confirmPassword) {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
      } else {
        //const url = 'http://localhost:5000/signup';
        const url = 'https://skiza.skizatune.com/signup';
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };
        const data = { mobile, password };

        fetch(url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              setError(response.error);
            } else {
              setMsg(response.message);
              setTimeout(() => {
                navigate('/sign-in');
              }, 2000);
            }
          })
          .catch((err) => setError(err.message));
      }
    } else {
      setError('All fields are required');
    }
  };

  return (
    <div className="room">
      <form className="form">
        <p>{msg ? <span className="success">{msg}</span> : <span className="error">{error}</span>}</p>
        <p className="label">Phone number</p>
        <input
          type="text"
          placeholder="Mobile number"
          className="input"
          value={mobile}
          onChange={(e) => handleInputChange(e, 'mobile')}
        />
        <p className="label">Password</p>
        <div className="password-container">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            className="input"
            value={password}
            onChange={(e) => handleInputChange(e, 'password')}
          />
          <button
            type="button"
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        <p className="label">Confirm Password</p>
        <div className="password-container">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm Password"
            className="input"
            value={confirmPassword}
            onChange={(e) => handleInputChange(e, 'confirmPassword')}
          />
          <button
            type="button"
            className="toggle-password"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        <button type="submit" className="Submit" onClick={handleSubmit}>
          Sign Up
        </button>
        <br />
        <Link to="/sign-in" className="account">
          I already have an account
        </Link>
      </form>
      <Footer />
    </div>
  );
}

export default SignUp;
