import React, { useState } from 'react';
import './BizzDescription.css';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function BizzDescription() {
  const [ownerName, setOwnerName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = (e) => {
    e.preventDefault();

    if (ownerName && businessName && phoneNumber && description) {
      const data = {
        ownerName,
        businessName,
        phoneNumber,
        description,
      };

      //const url = 'http://localhost:5000/business';
      const url = 'https://skiza.skizatune.com/business';
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            setError(response.error);
          } else {
            setMsg(response.message);
            // Redirect to the business page after successful submission
            setTimeout(() => {
              navigate('/Business'); 
            }, 2000); // Wait for 2 seconds before redirecting
          }
        })
        .catch((err) => setError(err.message));
    } else {
      setError('All fields are required');
    }
  };

  return (
    <>
      <div className='room'>
        <form className='form'>
          <p>{msg ? <span className="success">{msg}</span> : <span className="error">{error}</span>}</p>
          <p className='label'>Business Owner</p>
          <input
            type="text"
            placeholder="Enter your name"
            className='input'
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
            required
          />
          <p className='label'>Business Name</p>
          <input
            type="text"
            placeholder="Enter name of your business"
            className='input'
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
          />
          <p className='label'>Phone number</p>
          <input
            type="text"
            placeholder="Phone number"
            className='input'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <p className='label'>Business Description</p>
          <textarea
            className='descriptionInput'
            placeholder='Briefly describe your business'
            rows={4}
            cols={50}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <br />
          <button className='submit' onClick={handleSubmit}>Submit</button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default BizzDescription;
