import React from 'react'
import Footer from '../Footer'
import ReactAudioPlayer from 'react-audio-player'
import { Link } from 'react-router-dom'

function Margret() {
  return (
    <div>
      <>
        <div className='pic'>
          <img src="/images/margret.jpg" alt="Artist" className='martha' />
        </div>
        <div className='container'>
          <h1>Margret James</h1>
          <ul className='list'>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391289/" className='songName'>Unaweza verse1</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391289/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391279/" className='songName'>Ungehesabu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391279/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391278" className='songName'>Unifundishe</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391278">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391283/" className='songName'>Jina langu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391283/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391286" className='songName'>Ungehesabu part 2</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391286">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391288" className='songName'>Unaweza Chorus</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391288">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391285" className='songName'>Jina langu part 2</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391285">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391287" className='songName'>Ungehesabu part 1</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391287">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="/audio/magretJamesUnaweza.mpeg" />
              <a href="https://skizatune.com/6391277" className='songName'>Unaweza</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391277">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391281" className='songName'>Wewe ni njia</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391281">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391280" className='songName'>Nimekuona Bwana</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391280">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391282" className='songName'>Umenikumbuka</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391282">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6391284" className='songName'>Jina langu part 2</a>
              <Link className='dowload-btn' to="https://skizatune.com/6391284">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
          </ul>
        </div>
      </>
      <Footer />
    </div>
  )
}

export default Margret
