import React, { useState } from 'react';
import Footer from '../Footer';
import './SignUp.css';
import { Link, useNavigate } from 'react-router-dom';

function SignIn() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');

  const handleInputChange = (e, type) => {
    if (type === 'mobile') setMobile(e.target.value);
    if (type === 'password') setPassword(e.target.value);
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    if (mobile !== '' && password !== '') {
      //const url = 'http://localhost:5000/signin';
      const url = 'https://skiza.skizatune.com/signin';
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      const data = { mobile, password };

      fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            setError(response.error);
          } else {
            setMsg(response.message);
            // Redirect to HeroSection after successful login
            setTimeout(() => {
              localStorage.setItem('login', true);
              navigate('/');
            }, 2000); // Adjust delay as needed
          }
        })
        .catch((err) => setError(err.message));
    } else {
      setError('All fields are required');
    }
  };

  return (
    <div className="room">
      <form className="form">
        <p>{error ? <span className="error">{error}</span> : <span className="success">{msg}</span>}</p>
        <p className="label">Phone number</p>
        <input
          type="text"
          placeholder="Mobile number"
          className="input"
          value={mobile}
          onChange={(e) => handleInputChange(e, 'mobile')}
          required
        />
        <p className="label">Password</p>
        <div className="password-container">
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            className="input"
            value={password}
            onChange={(e) => handleInputChange(e, 'password')}
            required
          />
          <button
            type="button"
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? 'Hide' : 'Show'}
          </button>
        </div>
        <button type="submit" className="Submit" onClick={loginSubmit}>
          Sign In
        </button>
        <br />
        <Link to="/sign-up" className="account">
          I don’t have an account
        </Link>
      </form>
      <Footer />
    </div>
  );
}

export default SignIn;
