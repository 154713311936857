import React from 'react';
import "./Martha.css";
import Footer from '../Footer';
import ReactAudioPlayer from 'react-audio-player';
import { Link } from 'react-router-dom';

function Danken() {
  return (
    <div>
      <div className='pic'>
        <img src="/images/boaz.jpg" alt="Artist" className='martha' />
      </div>
      <div className='container'>
        <h1>Boaz Danken</h1>
        <ul className='list'>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/haufananishwiboaz.mpeg" />
            <a href="https://skizatune.com/6390059/" className='songName'>Haufananishwi</a>  
            <Link className='dowload-btn' to="https://skizatune.com/6390059/">
              <img src='/images/download.png' alt='skiza'/>
            </Link>  
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/jinaboaz.mpeg" />
            <a href="https://skizatune.com/6390061/" className='songName'>Jina la Yesu Linatisha</a>  
            <Link className='dowload-btn' to="https://skizatune.com/6390061/">
              <img src='/images/download.png' alt='skiza'/>
            </Link>
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/kumbukumbuboaz.mpeg" />          
            <a href="https://skizatune.com/6390068" className='songName'>Kumbukumbu Langu</a>  
            <Link className='dowload-btn' to="https://skizatune.com/6390068">
              <img src='/images/download.png' alt='skiza'/>
            </Link> 
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/sitaachaboaz.mpeg" />
            <a href="https://skizatune.com/6390066/" className='songName'>Sitaacha Kumsubiri Mungu</a>  
            <Link className='dowload-btn' to="https://skizatune.com/6390066/">
              <img src='/images/download.png' alt='skiza'/>
            </Link>
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/haleluhyaboaz.mpeg" />
            <a href="https://skizatune.com/6390058" className='songName'>Haleluhya Mungu</a>  
            <Link className='dowload-btn' to="https://skizatune.com/6390058">
              <img src='/images/download.png' alt='skiza'/>
            </Link>
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/nakushukuruboaz.mpeg" />
            <a href="https://skizatune.com/6390064" className='songName'>Ninakushukuru</a>  
            <Link className='dowload-btn' to="https://skizatune.com/6390064">
              <img src='/images/download.png' alt='skiza'/>
            </Link>
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/uinuliweboaz.mpeg" />
            <a href="https://skizatune.com/6390067" className='songName'>Uinuliwebo</a>  
            <Link className='dowload-btn' to="https://skizatune.com/6390067">
              <img src='/images/download.png' alt='skiza'/>
            </Link> 
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/niugusemoyo.mpeg" />          
            <a href="https://skizatune.com/6390060" className='songName'>Niugusemoyo</a>  
            <Link className='dowload-btn' to='https://skizatune.com/6390060'>
              <img src='/images/download.png' alt='skiza'/>
            </Link> 
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/mungumwenyeisharaboaz.mpeg" />
            <a href='https://skizatune.com/6390062' className='songName'>Mungu mwenye ishara</a>  
            <Link className='dowload-btn' to='https://skizatune.com/6390062'>
              <img src='/images/download.png' alt='skiza'/>
            </Link>
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/ameshinda.mpeg" />
            <a href='https://skizatune.com/6390057' className='songName'>Ameshinda Yesu</a>  
            <Link className='dowload-btn' to='https://skizatune.com/6390057'>
              <img src='/images/download.png' alt='skiza'/>
            </Link>  
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/ninakupenda.mpeg" />
            <a href='https://skizatune.com/6390063' className='songName'>Ninakupenda</a>  
            <Link className='dowload-btn' to='https://skizatune.com/6390063'>
              <img src='/images/download.png' alt='skiza'/>
            </Link> 
          </li>
          <li className='list-items'>
            <ReactAudioPlayer className='play' controls src="/audio/niugusemoyoboaz.mpeg" />
            <a href='https://skizatune.com/6390065' className='songName'>Niugusemoyo</a>  
            <Link className='dowload-btn' to='https://skizatune.com/6390065'>
              <img src='/images/download.png' alt='skiza'/>
            </Link>
          </li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Danken;
