import React from 'react'
import "./Martha.css"
import Footer from '../Footer'
import ReactAudioPlayer from 'react-audio-player'
import { Link } from 'react-router-dom'

function Dinu() {
  return (
    <div>
      <>
        <div className='pic'>
          <img src="/images/dinu.jpg" alt="Artist" className='martha' />
        </div>
        <div className='container'>
          <h1>Dinu Zeno</h1>
          <ul className='list'>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="/audio/kijitodinu.mpeg" />
              <a href="https://skizatune.com/6390082/" className='songName'>Kijito cha Utakaso</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390082/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390089/" className='songName'>Kale Nilitembea</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390089/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390081" className='songName'>Kale Utakaso</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390081">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390080/" className='songName'>Kale Mwokozi</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390080/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390088" className='songName'>Kale Mteteeni Yesu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390088">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390085" className='songName'>Kale Mteteeni Yesu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390085">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390084/" className='songName'>Kale Mteteeni Yesu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390084">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390086" className='songName'>Kale Mteteeni Yesu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390086">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390087" className='songName'>Kale Mteteeni Yesu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390087">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390083/" className='songName'>Kale Mteteeni Yesu</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390083/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
            <li className='list-items'>
              <ReactAudioPlayer className='play' controls src="" />
              <a href="https://skizatune.com/6390078/" className='songName'>Twonane milele</a>
              <Link className='dowload-btn' to="https://skizatune.com/6390078/">
                <img src='/images/download.png' alt='skiza' />
              </Link>
            </li>
          </ul>
        </div>
      </>
      <Footer />
    </div>
  )
}

export default Dinu
